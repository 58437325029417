import React, { ReactNode } from "react";
import { Close } from "@/components/Icons";
import Button from "../Button";

interface BaseModalProps {
  closeModalHandler?: () => void;
  children: ReactNode;
  title?: string;
  className?: string;
  footer?: ReactNode;
}

const BaseModal: React.FC<BaseModalProps> = ({
  closeModalHandler,
  children,
  title,
  className,
  footer,
}) => {
  return (
    <div
      id="default-modal"
      className="fixed top-0 right-0 left-0 z-[100] flex justify-center items-end md:items-center w-full h-full overflow-y-auto"
      aria-hidden="true"
    >
      {/* Background overlay */}
      <div className="bg-dashboard-frame blur-[6px] opacity-[0.88] fixed inset-0"></div>

      {/* Modal wrapper */}
      <div className="h-full md:h-auto md:max-w-[440px] w-full md:!bottom-[unset] flex items-end">
        <div className="relative bg-blackshade-900 shadow-frame rounded-t-3xl md:rounded-3xl max-h-[100vh] md:h-auto overflow-y-auto w-full">
          {/* Modal header */}
          {title && (
            <div className="flex items-center justify-between px-4 py-3 relative">
              <h3 className="text-label-lg md:text-title-lg text-white">
                {title}
              </h3>
              {closeModalHandler && (
                <Button
                  model="ghost"
                  size="sm"
                  className="px-2"
                  onClick={closeModalHandler}
                >
                  <Close />
                </Button>
              )}
              <div className="bg-border-linear h-[1px] left-0 right-0 absolute bottom-0" />
            </div>
          )}

          {/* Modal body */}
          <div className="p-4 text-white overflow-y-auto max-h-[70vh] md:max-h-[unset]">
            {children}
          </div>

          {/* Modal footer */}
          {footer && (
            <div className="flex items-center p-4 justify-end relative">
              <div className="bg-border-linear h-[1px] w-full absolute top-0" />
              {footer}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default BaseModal;
