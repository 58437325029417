import Button from "@/components/Common/Button";
import {
  Dollar,
  Earth,
  LayersMinimalistic,
  Nft,
  TagHorizontal,
} from "@/components/Icons";

const Card = ({
  tile_count,
  purchasepriceusd,
  image,
  landtype,
  tokenId,
  handleStaking,
  coordination,
  is_staked,
}) => {
  const userTiles = [
    {
      text: tokenId,
      icon: <TagHorizontal />,
      title: "Token/Land Id",
    },
    {
      text: purchasepriceusd,
      icon: <Dollar />,
      title: "Value",
    },
    {
      text: tile_count,
      icon: <LayersMinimalistic />,
      title: "Tiles",
    },
    {
      text: landtype,
      icon: <Nft className="h-4 w-4" />,
      title: "NFT Type",
    },
  ];
  return (
    <div className="bg-blackshade-800 rounded-[20px] p-4 relative">
      <Button
        model="tonal"
        className="absolute right-8 top-8"
        size="xsm"
        href={
          coordination
            ? `/lands?lat=${coordination.latitude}&lng=${coordination.longitude}`
            : "/"
        }
      >
        <Earth className="w-5 h-5" />
      </Button>
      <img
        src={image}
        alt="NFT"
        className="object-fit-cover d-block rounded-xl h-[126px] w-full"
      />
      <div className="my-4">
        {userTiles.map((item, index) => (
          <div className="flex justify-between items-center" key={index}>
            <div className="flex items-center text-label-md gap-1 text-blackshade-200">
              {item.icon}
              <span>{item.title}</span>
            </div>
            <div className="text-label-lg text-white">{item.text}</div>
          </div>
        ))}
      </div>
      <div className="flex gap-4">
        <Button
          className="!w-1/2"
          model="outlined"
          size="sm"
          disabled={is_staked}
          onClick={handleStaking}
        >
          Stake
        </Button>
        {is_staked ? (
          <Button disabled model="error" size="sm" className="w-1/2">
            Sell
          </Button>
        ) : (
          <a
            className="button error !w-1/2"
            disabled={is_staked}
            target="_blank"
            rel="noopener noreferrer"
            href={`https://opensea.io/assets/matic/${process.env.REACT_APP_LAND_TO_NFT_CONTRACT_ADDRESS}/${tokenId}`}
          >
            Sell
          </a>
        )}
      </div>
    </div>
  );
};

export default Card;
