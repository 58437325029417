import React, { useState } from "react";
import { copyToClipboard } from "@/utils/copyToClipboard.js";
import Input from "@/components/Common/Input";
import { Copy, StarsRoad } from "@/components/Icons";
import circleLine from "@/scss/images/circle-lined.png";
import { contractsList } from "./consts";
const Contracts = () => {
  const [clipboardText, setClipboardText] = useState("");

  return (
    <section className="relative pb-4 overflow-hidden">
      <StarsRoad className="absolute -top-14 left-0 right-0" />
      <div className="container">
        <h4
          data-aos="fade-in"
          data-aos-duration="800"
          className="text-headline-md desktop:text-display-md mt-8 mb-6 desktop:mt-[77px] desktop:mb-10 text-center"
        >
          <span className="text-gradiant">Smart</span> Contract Addresses
        </h4>
        <article
          className="flex flex-col gap-8 max-w-[850px] mx-auto"
          data-aos="fade-in"
          data-aos-duration="800"
        >
          {contractsList.map((contract, index) => (
            <div
              className={`${
                clipboardText === contract.value
                  ? "gradient-border-box"
                  : "bg-dashboard-frame rounded-3xl frame-effects p-6"
              }  p-6 !block`}
              key={index}
            >
              {clipboardText === contract.value ? (
                <>
                  {" "}
                  <img
                    src={circleLine}
                    alt=""
                    className="absolute rotate-180 -right-[22rem] desktop:-right-[50%] top-1/2 -translate-y-1/2"
                  />
                  <img
                    src={circleLine}
                    alt=""
                    className="absolute -left-[22rem] desktop:-left-[50%] top-1/2 -translate-y-1/2"
                  />
                </>
              ) : (
                ""
              )}

              <div>
                <h5 className="text-title-sm sm:text-title-lg mb-4 desktop:text-headline-sm desktop:mb-3">
                  {contract.title}
                </h5>
                <div
                  className="w-full cursor-pointer"
                  onClick={() => {
                    copyToClipboard(contract.value);
                    setClipboardText(contract.value as string);
                  }}
                >
                  <Input
                    type="text"
                    id="username"
                    defaultValue={contract.value}
                    value={contract.value}
                    readOnly
                    suffixIcon={<Copy className="text-gold-500" />}
                  />
                </div>
              </div>
            </div>
          ))}{" "}
        </article>
      </div>
    </section>
  );
};

export default Contracts;
