import React, { useEffect } from "react";
import AOS from "aos";
import Footer from "./Footer";
import Header from "./Header";

const HomeLayout = ({ element }) => {
  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
  }, []);
  
  
  return (
    <div>
      <Header />
      {element}
      <Footer />
    </div>
  );
};

export default HomeLayout;
