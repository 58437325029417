import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from "react";
import Cookies from "js-cookie";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { useAccount, useDisconnect } from "wagmi";
import Loading from "@/components/Common/Loading";
import { api } from "../utils/axiosInstance.js";
import AnnouncementModal from "@/components/Common/AnnouncementModal";
import { useToast } from "@/components/Common/Toast/utils";
import COOKIE_KEYS from "@/utils/cookieKeys";
import MaintenanceModal from "@/components/Common/MaintenanceModal";

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isFetchingUserData, setIsFetchingUserData] = useState(false);
  const [showSignModal, setShowSignModal] = useState(false);
  const [isSigned, setIsSigned] = useState(false);
  const [isSignupComplete, setIsSignupComplete] = useState(false);
  const [showAnnouncement, setShowAnnouncement] = useState(false);
  // const [showMaintenance, setShowMaintenance] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { disconnect } = useDisconnect();
  const AnnouncementCookie = Cookies.get("showAnnouncement");
  const { address, isConnected, status } = useAccount();
  const { addToast } = useToast();

  const hideAnnouncementModal = () => {
    setShowAnnouncement(false);
  };
  const showAnnouncementModal = () => {
    setShowAnnouncement(true);
    Cookies.set("showAnnouncement", true);
  };
  const dashboardAPI = () => {
    setIsLoading(true);
    api
      .post("/dashboard/", {
        user_address: address,
      })
      .then((res) => {
        setUser(res.data.data);
        setIsLoading(false);
      })
      .finally(() => setIsLoading(false));
  };

  const verifyUser = async (signature, navigator) => {
    setIsLoading(true);
    await api
      .post("/verify/", {
        user_address: address,
        signature,
      })
      .then((response) => {
        if (response.data.status === "Success") {
          Cookies.set(
            COOKIE_KEYS.refreshToken,
            response.data.refresh_token
            //    {
            //   httpOnly: true, // Prevents JS access
            //   secure: true, // Ensures it's sent only over HTTPS
            //   sameSite: "Strict", // Prevents CSRF
            // }
          );

          // Store access token in memory (or Redux/Zustand)
          sessionStorage.setItem(
            COOKIE_KEYS.accessToken,
            response.data.access_token
          );
          setIsAuthenticated(true);
          navigate("/dashboard/profile");
          showAnnouncementModal();
          dashboardAPI();
          setIsSignupComplete(true);
          // api
          //   .post("/check_user/", {
          //     user_address: address,
          //   })
          //   .then((response) => {
          //     Cookies.set("walletAddress", address);
          //     if (response.data.data) {
          //       setIsAuthenticated(true);
          //       navigate("/dashboard/profile");
          //       showAnnouncementModal();
          //       dashboardAPI();
          //       setIsSignupComplete(true);
          //       // addToast("success", "You have logged in successfully.");
          //     } else {
          //     }
          //     setIsLoading(false);
          //   })
          //   .catch((error) => {
          //     console.error("POST Error:", error);
          //     setIsLoading(false);
          //     addToast("danger", "Something went wrong in checking user. ");
          //   });
        }
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data?.message);
          console.log(error.response.status);
          console.log(error.response.headers);
          if (error.response.status === 404) {
            navigate("/signup");
            setIsSigned(true);
            addToast("info", "Please Complete the signup form.");
          }
        } // handleDisconnection();
        setIsLoading(false);
      });
  };
  const handleDisconnection = () => {
    disconnect();
    setIsSigned(false);
    setIsFetchingUserData(false);
    setIsAuthenticated(false);
    setIsLoading(false);
    setUser(null);
    Cookies.remove("walletAddress");
  };

  useEffect(() => {
    if (!AnnouncementCookie && !showAnnouncement && user) {
      showAnnouncementModal();
    }
  }, [showAnnouncement, user]);
  useEffect(() => {
    const handleNavigation = () => {
      if (
        location.pathname !== "/signup" &&
        location.pathname !== "/terms-conditions" &&
        !isSignupComplete &&
        !user
      ) {
        handleDisconnection();
      }
    };

    handleNavigation();
  }, [location.pathname, isSignupComplete, user]);

  useEffect(() => {
    if (!isConnected) {
      handleDisconnection();
    }
  }, [isConnected]);

  useEffect(() => {
    if (
      status !== "reconnecting" &&
      !isAuthenticated &&
      isConnected &&
      !isFetchingUserData &&
      !isSigned &&
      location.pathname !== "/terms-conditions"
    ) {
      setShowSignModal(true);
    }
  }, [
    status,
    address,
    isAuthenticated,
    isConnected,
    isFetchingUserData,
    isSigned,
  ]);
  useEffect(() => {
    const checkExpiration = () => {
      const storedData = sessionStorage.getItem(COOKIE_KEYS.signature);
      if (storedData) {
        const { expirationTime } = JSON.parse(storedData);
        if (Date.now() > expirationTime) {
          sessionStorage.removeItem(COOKIE_KEYS.signature);
          console.log("Signature expired and removed");
        }
      }
    };

    checkExpiration();
    const interval = setInterval(checkExpiration, 10000);

    return () => clearInterval(interval);
  }, []);
  useEffect(() => {
    if (address && !isAuthenticated) {
      setIsFetchingUserData(true);

      api
        .post("/dashboard/", {
          user_address: address,
        })
        .then((res) => {
          setUser(res.data.data);
          setIsLoading(false);
          setIsFetchingUserData(false);
          setIsAuthenticated(true)
        });

      // checkUser();
    } else {
      // navigate("/");
    }
  }, []);
  // useEffect(() => {
  //   setShowMaintenance(true);
  // }, []);
  return (
    <AuthContext.Provider
      value={{
        // connectWallet,
        verifyUser,
        isLoading,
        // checkUser,
        user,
        isAuthenticated,
        isConnected,
        address,
        isFetchingUserData,
        handleDisconnection,
        setIsSignupComplete,
        showSignModal,
        setShowSignModal,
      }}
    >
      {isLoading ? (
        <Loading />
      ) : (
        <>
          {children}
          {/* {showMaintenance && (
            <MaintenanceModal
              title="Polygon Network Maintenance"
              info={`Due to recent Polygon network issues, you may experience delays with core functions such as buying, selling, transferring, and NFT interactions.<br/>
We're working hard to resolve this as quickly as possible. In the meantime, please avoid these actions if possible.`}
              closeModalHandler={() => setShowMaintenance(false)}
            />
          )} */}
          {showAnnouncement && (
            <AnnouncementModal closeModalHandler={hideAnnouncementModal} />
          )}
        </>
      )}
    </AuthContext.Provider>
  );
};
