import Button from "@/components/Common/Button";
import React from "react";
import { useToast } from "@/components/Common/Toast/utils";

const ActionButtons = ({
  land,
  handleConvertToNFT,
  ComingSoonMessage,
  isLoading,
}) => {
  const { addToast } = useToast();

  return (
    <>
      {land?.is_nft && !isLoading ? (
        ""
      ) : (
        <>
          <Button
            onClick={() => handleConvertToNFT(land)}
            disabled={isLoading}
            model="info"
            size="sm"
          >
            Convert to NFT
          </Button>
          <Button
            model="error"
            size="sm"
            onClick={ComingSoonMessage}
            disabled={isLoading}
          >
            Sell
          </Button>
        </>
      )}
    </>
  );
};

export default ActionButtons;
