import {
  getDefaultConfig,
  RainbowKitProvider,
  darkTheme,
  connectorsForWallets,
} from "@rainbow-me/rainbowkit";
import { WagmiProvider, createStorage } from "wagmi";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import "@rainbow-me/rainbowkit/styles.css";
import { polygon } from "wagmi/chains";
import {
  rabbyWallet,
  walletConnectWallet,
  metaMaskWallet,
  coinbaseWallet,
} from "@rainbow-me/rainbowkit/wallets";

const projectId = process.env.REACT_APP_PROJECT_ID;
const connectors = connectorsForWallets(
  [
    {
      groupName: "Suggested",
      wallets: [
        rabbyWallet,
        walletConnectWallet,
        metaMaskWallet,
        coinbaseWallet,
      ],
    },
  ],
  {
    appName: "PCOLand",
    projectId,
  }
);
export const config = getDefaultConfig({
  appName: "PCOLand",
  projectId,
  chains: [polygon],
  storage: createStorage({
    storage: localStorage,
  }),
  connectors,
});

// const config = getDefaultConfig({
//   // connectors,
//   appName: "PCOLand",
//   projectId,
//   chains: [polygonAmoy],
//   ssr: false,
// });
const TWENTY_FOUR_HOURS_MS = 24 * 60 * 60 * 1000; // 24 hours in milliseconds

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: true,
      retry: false,
      staleTime: TWENTY_FOUR_HOURS_MS,
    },
  },
});

export const Web3ModalProvider = ({ children }) => {
  return (
    <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>
        <RainbowKitProvider modalSize="compact" theme={darkTheme()}>
          {children}
        </RainbowKitProvider>
      </QueryClientProvider>
    </WagmiProvider>
  );
};
