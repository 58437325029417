import React, { useState, useEffect } from "react";
import RangeSlider from "@/components/Common/RangeSlider";
import Input from "@/components/Common/Input";
import Radio from "@/components/Common/Radio";

interface Props {
  tile_count: string;
  purchase_price_token: number;
  tokenPercentageValue: number;
  setTokenPercentageValue: (value: number) => void;
  selectedTokenValue: string;
  setSelectedTokenValue: (value: string) => void;
}

const TokenSelector: React.FC<Props> = ({
  tile_count,
  purchase_price_token,
  tokenPercentageValue,
  setTokenPercentageValue,
  selectedTokenValue,
  setSelectedTokenValue,
}) => {
  const [pmeAmount, setPmeAmount] = useState<number>(purchase_price_token);
  const [pmgAmount, setPmgAmount] = useState<number>(0);

  const handleChange = (value: string) => {
    setSelectedTokenValue(value);
    setTokenPercentageValue(Number(80));
  };
  const handleSliderChange = (value: number) => {
    let maxSliderValue;
    if (selectedTokenValue === "PME") {
      maxSliderValue = 20;
    } else {
      maxSliderValue = 80;
    }

    const adjustedValue = Math.min(value, maxSliderValue);

    setTokenPercentageValue(adjustedValue);
  };

  useEffect(() => {
    if (selectedTokenValue === "PMG") {
      const pmg = (tokenPercentageValue / 100) * purchase_price_token;
      const pme = purchase_price_token - pmg;
      setPmgAmount(pmg);
      setPmeAmount(pme);
    } else {
      // Reset values when only PME is selected
      setPmgAmount(0);
      setPmeAmount(purchase_price_token);
    }
  }, [tokenPercentageValue, selectedTokenValue, purchase_price_token]);

  return (
    <div className="relative">
      <Radio
        id="radio-1"
        name="group1"
        value="PME"
        label="Pay with PME Only"
        checked={selectedTokenValue === "PME"}
        onChange={handleChange}
      />
      <Radio
        id="radio-2"
        name="group1"
        value="PMG"
        label="Pay with PME & PMG"
        checked={selectedTokenValue === "PMG"}
        onChange={handleChange}
      />

      <div
        className={`border-t border-blackshade-400 pt-6 ${
          selectedTokenValue === "PMG" ? "flex gap-4" : ""
        }`}
      >
        {selectedTokenValue === "PMG" && (
          <Input
            label="PMG Amount"
            readOnly
            disabled
            value={pmgAmount.toFixed(2)}
            model="lg"
            suffixIcon={<div>PMG</div>}
          />
        )}

        <Input
          label="PME Amount"
          readOnly
          disabled
          value={pmeAmount.toFixed(2)}
          model="lg"
          suffixIcon={<div>PME</div>}
        />
      </div>
      {selectedTokenValue === "PMG" && (
        <div className="mt-10 relative">
          <div className="flex justify-between absolute left-0 right-0 bottom-8">
            <div>PMG</div>
            <div>PME</div>
          </div>
          <RangeSlider
            min={0}
            max={100}
            step={1}
            values={[tokenPercentageValue]}
            onChange={(values) => handleSliderChange(values[0])}
            tooltipMessage={`${tokenPercentageValue}% PMG - ${
              100 - tokenPercentageValue
            }%PME`}
          />
        </div>
      )}
    </div>
  );
};

export default TokenSelector;
