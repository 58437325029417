import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import About from "./components/About";
import RoadMap from "./components/roadmap";
import Work from "./components/work";
import Blog from "./components/blog";
import blogData from "./consts/blogData";
import FAQ from "./components/faqs";
import News from "./components/news";
import Exchanges from "./components/Exhcanges";
import exchangesData from "./consts/exchangeData";
import HeroHeader from "./components/HeroHeader";
import Subscription from "./components/Subscription";
import Statistics from "./components/Statistics";

const Home = () => {
  const location = useLocation();
  useEffect(() => {
    const hash = location.hash;
    if (hash) {
      const element = document.querySelector(hash);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  return (
    <div className="">
      <HeroHeader />
      <About />
      <Statistics />
      <RoadMap />
      <Work />
      <Blog data={blogData} />
      <FAQ />
      <News />
      <Exchanges data={exchangesData} />
      <Subscription />
    </div>
  );
};

export default Home;
