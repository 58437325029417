import axios from "axios";
import axiosRetry from "axios-retry";
import Cookies from "js-cookie";
import COOKIE_KEYS from "./cookieKeys";
import { disconnect } from "@wagmi/core"; 
import { config } from "@/context/web3ModalProvider";

const handleLogout = () => {
  disconnect(config);
  Cookies.remove(COOKIE_KEYS.refreshToken);
  sessionStorage.removeItem(COOKIE_KEYS.accessToken);
  setTimeout(() => {
    if (window.location.pathname !== "/") {
      window.location.href = "/";
    }
  }, 3000);
};
export const api = axios.create({
  baseURL: process.env.REACT_APP_API_KEY,
  timeout: 120000, // Request timeout
  headers: {
    "Content-Type": "application/json",
  },
  // withCredentials: true,
});

api.interceptors.request.use(
  (config) => {
    const accessToken = sessionStorage.getItem(COOKIE_KEYS.accessToken);
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    } else {
      console.warn("No token found in cookies");
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosRetry(api, {
  retries: Infinity,
  shouldResetTimeout: true,
  retryCondition: (error) => {
    console.log("Retrying due to error:", error);
    return (
      error.response && [500, 502, 503, 504].includes(error.response.status)
    );
  },
  retryDelay: (retryCount) => {
    return 30000;
  },
});

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        const refreshResponse = await axios.post(
          `${process.env.REACT_APP_API_KEY}refresh_token/`,
          { refresh_token: Cookies.get(COOKIE_KEYS.refreshToken) },
          { headers: { "Content-Type": "application/json" } }
        );

        const newAccessToken = refreshResponse.data.access_token;

        sessionStorage.setItem(COOKIE_KEYS.accessToken, newAccessToken);

        originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
        return api(originalRequest);
      } catch (refreshError) {
        console.error("Refresh token failed", refreshError);
        handleLogout(); // Logout if refresh fails
      }
    }
    return Promise.reject(error);
  }
);
