/* eslint-disable no-undef */
import { useRef, useEffect, useState } from "react";
import { api } from "@/utils/axiosInstance";
import {
  useWriteContract,
  useAccount,
  useReadContract,
  useBalance,
} from "wagmi";
import { landNFTAbi, pmeApproveAbi } from "@/utils/contractConstants";
import { useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import LandTypeSelector from "./LandTypeSelector";
import Map from "./Map";
import Stepper from "@/components/Common/Stepper";
import BaseModal from "@/components/Common/BaseModal";
import { Nft, Refresh } from "@/components/Icons";
import Button from "@/components/Common/Button";
import { useToast } from "@/components/Common/Toast/utils";
import { formatUnits } from "viem";

const Modal = ({ landDetails, closeModalHandler }) => {
  const navigate = useNavigate();
  const { addToast } = useToast();

  const viewerRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isMapLoaded, setIsMapLoaded] = useState(false);
  const [sid, setSid] = useState();
  const [landTypes, setLandTypes] = useState([]);
  const [showStepper, setShowStepper] = useState(false);
  const [selectedLandType, setSelectedLandType] = useState("");

  const { address } = useAccount();
  const queryClient = useQueryClient();

  const landConvertor = useWriteContract();

  const pmeBalance = useBalance({
    address,
    token: process.env.REACT_APP_PME_CONTRACT_ADDRESS,
  });
  const pmgBalance = useBalance({
    address,
    token: process.env.REACT_APP_PMG_CONTRACT_ADDRESS,
  });
  const maticBalance = useBalance({
    address,
    token: process.env.REACT_APP_MATIC_CONTRACT_ADDRESS,
  });

  const convertLandToNFT = async (sid) => {
    await landConvertor.writeContractAsync({
      address: process.env.REACT_APP_LAND_TO_NFT_CONTRACT_ADDRESS,
      abi: landNFTAbi,
      functionName: "convertLandToNFT",
      args: [landDetails.land_id, sid],
      value: BigInt(landDetails.fee_value),
      // gasLimit: 300000,
    });
  };
  const allowance = useReadContract({
    address: process.env.REACT_APP_PME_CONTRACT_ADDRESS,
    abi: pmeApproveAbi,
    functionName: "allowance",
    args: [address, process.env.REACT_APP_LAND_TO_NFT_CONTRACT_ADDRESS],
    watch: true,
  });

  useEffect(() => {
    if (landConvertor.isSuccess) {
      closeModalHandler();
      addToast(
        "success",
        "Your Land Has Been Successfully Converted to an NFT!"
      );

      navigate("/dashboard/my-NFTs");
    }
  }, [landConvertor.isSuccess]);

  useEffect(() => {
    allowance.refetch();
    queryClient.invalidateQueries({ queryKey: pmeBalance.queryKey });
    queryClient.invalidateQueries({ queryKey: pmgBalance.queryKey });
  }, []);

  const captureImage = () => {
    return new Promise((resolve, reject) => {
      if (viewerRef.current) {
        const viewer = viewerRef.current;
        viewer.scene.requestRender();
        const listener = () => {
          viewer.scene.postRender.removeEventListener(listener);
          const canvas = viewer.scene.canvas;
          const image = canvas.toDataURL("image/png");
          if (image) {
            resolve(image);
          } else {
            reject(new Error("Failed to capture image."));
          }
        };
        viewer.scene.postRender.addEventListener(listener);
      } else {
        reject(new Error("Viewer is not available."));
      }
    });
  };
  const getNftData = async () => {
    const picture = await captureImage();
    setIsLoading(true);
    setShowStepper(true);

    await api
      .post("/get_nft/", {
        land_id: landDetails.land_id,
        picture,
        land_type: selectedLandType,
        user_address: address,
      })
      .then((res) => {
        setSid(res.data.sid);

        // approveHandler();
        convertLandToNFT(res.data.sid);
      });
    // .finally(() => setIsLoading(false));
  };
  const convertButtonHandler = async () => {
    const feeValuePol = formatUnits(landDetails.fee_value.toString(), 18);
    try {
      if (Number(maticBalance?.data?.formatted) < feeValuePol) {
        addToast(
          "danger",
          "Your POL balance is not sufficient to complete the process."
        );
      } else {
        getNftData();
      }
    } catch (error) {
      console.error("Error capturing image:", error);
      setSid(null);
    }
  };

  const isBtnDisabled = isLoading || !isMapLoaded || landConvertor.isPending;

  const stepperList = [
    {
      id: 1,
      title: "NFT Information",
      info:
        isLoading && !sid
          ? "Retrieving NFT Information"
          : sid === null
          ? "Failed to Retrieve NFT Information"
          : sid
          ? "Retrieved NFT Information"
          : "NFT Information",
      status:
        isLoading && !sid
          ? "in-progress"
          : sid
          ? "active"
          : sid === null
          ? "failed"
          : "in-active",
      retryHandler:
        !sid && !isLoading && sid === null ? convertButtonHandler : null,
      icon: <Nft />,
    },
    {
      id: 3,
      title: "Convert",

      info: landConvertor.isPending
        ? "Waiting for Wallet Confirmation To NFT"
        : landConvertor.isError
        ? "Failed to Convert Land to NFT"
        : landConvertor.isSuccess
        ? "Land Converted to NFT"
        : "Convert",
      status: landConvertor.isPending
        ? "in-progress"
        : landConvertor.isSuccess
        ? "active"
        : landConvertor.isError
        ? "failed"
        : "in-active",
      retryHandler: landConvertor.isError ? () => convertLandToNFT(sid) : null,
      icon: <Refresh />,
    },
  ];
  const totalStatusTitle =
    isLoading && !sid
      ? "Retrieving NFT Information"
      : sid === null
      ? "Failed to Retrieve NFT Information"
      : sid
      ? landConvertor.isPending
        ? "Waiting for Wallet Confirmation To NFT"
        : landConvertor.isError
        ? "Failed to Convert Land to NFT"
        : landConvertor.isSuccess
        ? "Land Converted to NFT"
        : "Retrieved NFT Information"
      : "Convert";

  const totalStatus =
    isLoading && !sid
      ? "in-progress"
      : sid === null
      ? "failed"
      : sid
      ? landConvertor.isPending
        ? "in-progress"
        : landConvertor.isSuccess
        ? "active"
        : landConvertor.isError
        ? "failed"
        : "active"
      : "in-active";

  const totalRetryHandler =
    !sid && !isLoading && sid === null
      ? convertButtonHandler
      : landConvertor.isError
      ? () => convertLandToNFT(sid)
      : null;

  return (
    <BaseModal
      title="Convert Your Land To NFT"
      closeModalHandler={closeModalHandler}
      footer={
        <div className="flex gap-4 flex-wrap w-full justify-end !flex-col-reverse desktop:!flex-row">
          {totalStatus === "failed" && (
            <Button
              model="ghost"
              size="sm"
              onClick={closeModalHandler}
              className="!w-full desktop:!w-max"
            >
              Cancel
            </Button>
          )}
          <Button
            model="main"
            size="sm"
            className="!w-full desktop:!w-max"
            onClick={
              totalStatus === "failed"
                ? totalRetryHandler
                : convertButtonHandler
            }
            disabled={
              (isBtnDisabled || selectedLandType.length === 0) &&
              totalStatus !== "failed"
            }
            isLoading={isLoading && totalStatus !== "failed"}
          >
            {totalStatus === "failed" ? "Try Again" : "Confirm"}
          </Button>
        </div>
      }
    >
      <Stepper
        list={stepperList}
        showStepper={showStepper}
        totalStatus={totalStatus}
        totalStatusTitle={totalStatusTitle}
      />
      {!showStepper && (
        <>
          {" "}
          <h3 className="text-label-lg mt-10 mb-6">
            Retrieved NFT Information
          </h3>
          <Map
            landDetails={landDetails}
            setIsMapLoaded={setIsMapLoaded}
            viewerRef={viewerRef}
          />
          <LandTypeSelector
            landDetails={landDetails}
            selectedLandType={selectedLandType}
            setSelectedLandType={setSelectedLandType}
            setLandTypes={setLandTypes}
            landTypes={landTypes}
            isBtnDisabled={isBtnDisabled || sid}
          />
        </>
      )}
    </BaseModal>
  );
};

export default Modal;
